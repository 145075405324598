import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { trpc } from "~/components/Utility/trpc";
import { useCreator } from "./context/creator";
import { useAgency } from "./context/agency";

export interface AppRerouteProps {
	children: React.ReactNode;
	allowedAccountTypes: ("creator" | "business" | "agency")[];
}

/**
 * Reroutes for core app pages.
 */
const AppReroute: React.FC<AppRerouteProps> = ({
	children,
	allowedAccountTypes,
}) => {
	const [hasRerouted, setHasRerouted] = useState(false);
	const { data: user, failureCount } = trpc.user.current.useQuery();
	const router = useRouter();

	const { creatorProfile, availableCreatorProfiles } = useCreator();
	const { agencyProfile, isLoading: isAgencyLoading } = useAgency();

	useEffect(() => {
		if (hasRerouted || isAgencyLoading) {
			return;
		}

		const areCreatorsAllowed = allowedAccountTypes.includes("creator");
		const areBusinessesAllowed = allowedAccountTypes.includes("business");
		const areAgenciesAllowed = allowedAccountTypes.includes("agency");

		if (!user && failureCount > 0) {
			if (areCreatorsAllowed) {
				router.push("/signin?next=" + router.asPath);
			} else if (areAgenciesAllowed) {
				router.push("/agency/signin?next=" + router.asPath);
			} else {
				router.push("/business/signin?next=" + router.asPath);
			}
			setHasRerouted(true);
			return;
		} else if (user?.isDisabled) {
			router.push("/borked");
			setHasRerouted(true);
			return;
		}

		const redirect = () => {
			if (user?.creatorProfile && user?.creatorProfile?.agencyUsername) {
				router.push(`/join/${user?.creatorProfile.agencyUsername}`);
				setHasRerouted(true);
				return;
			} else if (user?.creatorProfile && !agencyProfile && !isAgencyLoading) {
				router.push("/creator/mediakit");
				setHasRerouted(true);
				return;
			} else if (user?.businessProfile) {
				if (user.businessProfile?.hasCompletedOnboarding) {
					router.push("/business/dashboard");
				} else {
					router.push("/business/onboarding");
				}
				setHasRerouted(true);
				return;
			} else if (agencyProfile) {
				router.push("/agency");
				setHasRerouted(true);
				return;
			}
		};

		if (user && router.isReady) {
			const meetsAllowedAccountTypes =
				(areBusinessesAllowed && !!user.businessProfile) ||
				(areAgenciesAllowed && !!user.agencies.length) ||
				(areCreatorsAllowed &&
					availableCreatorProfiles.length > 0 &&
					!user.creatorProfile?.agencyUsername);

			const needsOnboarding =
				router.pathname.startsWith("/agency/onboarding") &&
				agencyProfile?.hasCompletedOnboarding;

			if (!meetsAllowedAccountTypes || needsOnboarding) {
				redirect();
			}
		}
	}, [
		router,
		user,
		failureCount,
		hasRerouted,
		allowedAccountTypes,
		creatorProfile,
		agencyProfile,
		isAgencyLoading,
		availableCreatorProfiles,
	]);

	return <>{children}</>;
};

export default AppReroute;
